import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/* eslint-disable max-len */

const Icon = ({ closed }) => (
  <svg
    className="block h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d={
        closed
          ? 'M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
          : 'M6 18L18 6M6 6l12 12'
      }
    />
  </svg>
);

Icon.propTypes = {
  closed: PropTypes.bool.isRequired,
};

const NavLink = ({ children, href }) => (
  <Link
    to={href}
    className="nav-link text-white hover:text-blue-500 sm:pl-1 md:pl-3"
  >
    {children}
  </Link>
);

NavLink.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

const DropMenuItem = ({ children, href, onClick }) => (
  <div className="px-2 py-1">
    <Link
      to={href}
      className="text-sm hover:text-blue-500 text-uppercase"
      role="menuitem"
      onClick={onClick}
    >
      {children}
    </Link>
  </div>
);

DropMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Header = () => {
  const [showDropDownMenu, setShowDropDownMenu] = useState(false);
  const toggleMenu = () => {
    setShowDropDownMenu(!showDropDownMenu);
  };

  return (
    <nav className="bg-black">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={toggleMenu}
            >
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Icon closed={!showDropDownMenu} onClick={toggleMenu} />
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-center sm:justify-between">
            <div>
              <Link to="/">
                <img
                  className="h-10 w-auto"
                  src="/assets/images/laesco.gif"
                  alt="La Esco Logo"
                />
              </Link>
            </div>
            <div className="hidden sm:flex">
              <NavLink href="/reporte">Reporte</NavLink>
              <NavLink href="/category/bodyboard">Bodyboard</NavLink>
              <NavLink href="/category/surf">Surf</NavLink>
              <NavLink href="/category/viajes">Viajes</NavLink>
              <NavLink href="/category/entrevistas">Entrevistas</NavLink>
              <NavLink href="/category/fotografia">Fotograf&iacute;a</NavLink>
              <NavLink href="/category/ecologia">Ecolog&iacute;a</NavLink>
              <NavLink href="/category/videos">Videos</NavLink>
            </div>
          </div>
        </div>
        {showDropDownMenu && (
          <div
            className="absolute left-0 z-20 w-full bg-black text-white"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
          >
            <DropMenuItem onClick={toggleMenu} href="/">
              Inicio
            </DropMenuItem>
            <DropMenuItem onClick={toggleMenu} href="/reporte">
              Reporte
            </DropMenuItem>
            <DropMenuItem onClick={toggleMenu} href="/category/bodyboard">
              Bodyboard
            </DropMenuItem>
            <DropMenuItem onClick={toggleMenu} href="/category/surf">
              Surf
            </DropMenuItem>
            <DropMenuItem onClick={toggleMenu} href="/category/viajes">
              Viajes
            </DropMenuItem>
            <DropMenuItem onClick={toggleMenu} href="/category/entrevistas">
              Entrevistas
            </DropMenuItem>
            <DropMenuItem onClick={toggleMenu} href="/category/fotografia">
              Fotograf&iacute;a
            </DropMenuItem>
            <DropMenuItem onClick={toggleMenu} href="/category/ecologia">
              Ecolog&iacute;a
            </DropMenuItem>
            <DropMenuItem onClick={toggleMenu} href="/category/videos">
              Videos
            </DropMenuItem>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
