import React from 'react';

import Index from '../components/Index';
import {
  useFetchNewsQuery,
  useFetchWaveReportQuery,
} from '../features/api/apiSlice';

const Home = () => {
  const { data: news, isFetching, isSuccess } = useFetchNewsQuery();
  const { data: report, isFetching: loadingReport } = useFetchWaveReportQuery();

  return (
    <Index
      news={news}
      loading={isFetching}
      success={isSuccess}
      waveReport={report}
      loadingReport={loadingReport}
    />
  );
};

export default Home;
