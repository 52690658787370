import React from 'react';
import { useParams } from 'react-router-dom';

import Loading from '../components/utils/LoadingComponent';
import {
  useFetchSingleNewQuery,
  useFetchPostMediasQuery,
} from '../features/api/apiSlice';
import Carousel from '../components/Carousel';

const postImages = (featureImageId, medias) => {
  const filtered = medias?.filter((image) => image.id !== featureImageId) || [];
  const items = filtered.map((item) => ({
    url: item.media_details.sizes.full.source_url,
    alt_text: item.caption,
  }));
  return items;
};

const Post = () => {
  const { id } = useParams();

  // eslint-disable-next-line operator-linebreak
  const { data: postData, isFetching: loadingData } =
    useFetchSingleNewQuery(id);
  // eslint-disable-next-line operator-linebreak
  const { data: postMediasData, isFetching: loadingMedias } =
    useFetchPostMediasQuery(id);

  const carouselItems = postImages(postData?.featured_media, postMediasData);

  return (
    <div className="home-content-area flex flex-col py-4">
      <Loading loading={loadingData && loadingMedias} extraClasses="my-3">
        <Carousel items={carouselItems} />

        <div className="max-w-2xl mx-auto my-8">
          <h2 className="text-xl">{postData?.title.rendered}</h2>
          <div
            className="my-8 leading-loose text-sm"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: postData?.content.rendered }}
          />
        </div>
      </Loading>
    </div>
  );
};

export default Post;
