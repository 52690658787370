/* eslint-disable no-unused-vars */
import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../components/utils/LoadingComponent';
import { useFetchWaveReportQuery } from '../features/api/apiSlice';
import Carousel from '../components/Carousel';
import ReportMap from '../components/ReportMap';
import WindGuruIFrame from '../components/WindGuruIFrame';
import ReportList from '../components/ReportList';

const WaveReportPage = () => {
  const { date } = useParams();
  const { data, isFetching } = useFetchWaveReportQuery(date);

  // eslint-disable-next-line operator-linebreak
  const images =
    data?.photos_urls.map((item) => ({ url: item, alt_text: '' })) || [];

  return (
    <div className="home-content-area flex flex-col p-2 bg-white">
      <Loading loading={isFetching}>
        <div className="flex gap-2 justify-between">
          <ReportMap
            windDirection={data ? Number.parseInt(data.wind_direction, 10) : 0}
            waveDirection={data ? Number.parseInt(data.wave_direction, 10) : 0}
          />
          <Carousel items={images} />
        </div>
        <div className="">
          <WindGuruIFrame />
        </div>
        <ReportList
          title="Reportes anteriores"
          reports={data?.previous || []}
        />
        <ReportList
          title="Reportes destacados"
          reports={data?.previous_good || []}
        />
      </Loading>
    </div>
  );
};

export default WaveReportPage;
