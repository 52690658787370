import React from 'react';

const WindGuruIFrame = () => (
  <iframe
    src="http://wisuki.com/widget-details?spot=4038&lang=es&spotinfo=1&windunit=kh&waveunit=me&tempunit=c&interval1h=1"
    style={{ border: 0, width: '100%', height: '530px' }}
    frameBorder="0"
    title="WindGuru report"
  />
);

export default WindGuruIFrame;
