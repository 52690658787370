import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { stringToDate } from '../utils/dateFormat';

const ReportList = ({ title, reports }) => (
  <div>
    <div className="bg-blue-900 text-white mb-2 p-2">{title}</div>
    <div className="forecast-mini flex flex-wrap justify-between">
      {reports.map((report) => {
        const date = stringToDate(report.time);
        return (
          <div className="inline-block" key={report.forecast_id}>
            <Link to={`/reporte/${date}`}>
              <img
                src={report.photos_urls[0]}
                alt="Imagen reporte"
                className="object-cover"
              />
              <p>{date}</p>
            </Link>
          </div>
        );
      })}
    </div>
  </div>
);

ReportList.propTypes = {
  title: PropTypes.string.isRequired,
  reports: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ReportList;
