import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';

import Layout from './components/layout/Layout';
import Home from './pages/Home';
import Post from './pages/Post';
import WaveReportPage from './pages/WaveReportPage';

import './App.css';

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/reporte/:date" element={<WaveReportPage />} />
          <Route path="/reporte/" element={<WaveReportPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
);

export default App;
