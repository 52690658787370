import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../HeaderComponent';

const Layout = () => (
  <div className="home-main-container">
    <div className="home-header-area">
      <Header />
    </div>
    <Outlet />
    <div className="home-footer-area">
      <Footer />
    </div>
  </div>
);

export default Layout;
