import React from 'react';
import PropTypes from 'prop-types';

import mapa from '../assets/images/mapa.png';
import arrowWind from '../assets/images/flecha_viento.gif';
import arrowWave from '../assets/images/flecha_ola.gif';

function positionImage(left, top, angle) {
  const imgWidth = 25;

  const r = Math.round(52 - imgWidth / 2);

  const ang = -90 + angle;
  const x = Math.round(left + r * Math.cos((ang * Math.PI) / 180));
  const y = Math.round(top + r * Math.sin((ang * Math.PI) / 180));

  return {
    left: `${x}px`,
    top: `${y}px`,
    transform: `rotate(${ang - 90}deg)`,
  };
}

const ReportMap = ({ windDirection, waveDirection }) => {
  const windOrigin = {
    left: 212,
    top: 63,
  };

  const waveOrigin = {
    left: 78,
    top: 312,
  };

  return (
    <div className="wave-report-map relative">
      <img src={mapa} className="absolute" alt="Mapa de costa Escollera" />
      <img
        src={arrowWind}
        style={positionImage(windOrigin.left, windOrigin.top, windDirection)}
        className="absolute"
        alt="Direccion viento"
      />
      <img
        src={arrowWave}
        className="absolute"
        style={positionImage(waveOrigin.left, waveOrigin.top, waveDirection)}
        alt="Direccion olas"
      />
    </div>
  );
};

ReportMap.propTypes = {
  waveDirection: PropTypes.number.isRequired,
  windDirection: PropTypes.number.isRequired,
};

export default ReportMap;
