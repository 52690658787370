import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { backendUrl } from '../../shared/config';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${backendUrl}/wp-json`,
  }),
  endpoints: (build) => ({
    fetchNews: build.query({
      query: () => 'wp/v2/posts?_embed=wp:featuredmedia',
    }),
    fetchSingleNew: build.query({
      query: (postId) => `wp/v2/posts/${postId}`,
    }),
    fetchPostMedias: build.query({
      query: (postId) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        `wp/v2/media?parent=${postId}&orderby=id&order=asc&per_page=30`,
    }),
    fetchWaveReport: build.query({
      query: (date) => {
        const dateStr = date ? `?date=${date}` : '';
        return `laesco-reporte-olas/v1/reporte${dateStr}`;
      },
    }),
  }),
});

export const {
  useFetchNewsQuery,
  useFetchSingleNewQuery,
  useFetchPostMediasQuery,
  useFetchWaveReportQuery,
} = api;
