import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ loading, extraClasses, children }) => {
  if (!loading) {
    return children;
  }

  return (
    <div className={`linear-progress ${extraClasses}`}>
      <span className="linear-progress-background">
        <span className="linear-progress-loading" />
      </span>
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  extraClasses: PropTypes.string,
};

Loading.defaultProps = {
  loading: true,
  children: null,
  extraClasses: '',
};

export default Loading;
