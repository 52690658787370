import React from 'react';
import PropTypes from 'prop-types';

import Carousel from './Carousel';

const newsToCarouselItem = (news) => ({
  // eslint-disable-next-line no-underscore-dangle
  url: news._embedded['wp:featuredmedia'][0].media_details.sizes.full
    .source_url,
  alt_text: news.title.rendered,
  link: `/post/${news.id}`,
});

const Featured = ({ news }) => (
  <Carousel items={news.map(newsToCarouselItem)} />
);

Featured.propTypes = {
  news: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Featured;
