import { configureStore } from '@reduxjs/toolkit';

import { api } from '../features/api/apiSlice';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefMiddleware) => getDefMiddleware().concat(api.middleware),
});

export default store;
