import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ImageSkeleton from './utils/ImageSkeleton';
import { stringToTime } from '../utils/dateFormat';

const WaveReport = ({ report, loading }) => {
  if (loading) {
    return <ImageSkeleton />;
  }
  return (
    <div className="relative bg-white">
      <Link to="/reporte/">
        <img
          className="object-cover"
          src={report?.photos_urls[0]}
          alt="Reporte de olas"
        />
        <div className="home-forecast-caption absolute left-1 top-1 bg-cyan-600 text-white p-2">
          <b>REPORTE DE OLAS</b>
          {` ${report?.location} ${stringToTime(report?.time)}hs`}
        </div>
      </Link>
    </div>
  );
};

WaveReport.propTypes = {
  report: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
};

WaveReport.defaultProps = {
  report: null,
};

export default WaveReport;
