import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SectionLink = ({ href, children }) => (
  <Link className="px-1" to={href}>
    {children}
  </Link>
);

SectionLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

const Footer = () => (
  <div className="bg-white px-2 pt-2 md:px-8 md:pt-3 lg:px-32 lg:pt-8 ">
    <div className="flex flex-wrap justify-between">
      <div className="footer-nav flex flex-wrap items-center">
        <SectionLink href="/reporte">Reporte de olas</SectionLink>
        <SectionLink href="/bodyboard">BodyBoard</SectionLink>
        <SectionLink href="/surf">Surf</SectionLink>
        <SectionLink href="/viajes">Viajes</SectionLink>
        <SectionLink href="/entrevistas">Entrevistas</SectionLink>
        <SectionLink href="/fotografia">Fotografía</SectionLink>
        <SectionLink href="/ecologia">Ecología</SectionLink>
        <SectionLink href="/videos">Videos</SectionLink>
      </div>
      <div className="flex md:order-first justify-between items-center">
        <a href="https://www.facebook.com/laesco.com.ar/">
          <img src="/assets/images/social/media_fb.gif" alt="Facebook" />
        </a>
        <a href="http://twitter.com/laesconecochea">
          <img src="/assets/images/social/media_tw.gif" alt="Twitter" />
        </a>
        <a href="https://www.youtube.com/channel/UCcUUsdjUePo2DD7CrAD1OyA">
          <img src="/assets/images/social/media_yt.gif" alt="Youtube" />
        </a>
        <a href="https://www.instagram.com/laesco.arg">
          <img src="/assets/images/social/media_insta.gif" alt="Instagram" />
        </a>
        <a href="mailto:info@laesco.com.ar">
          <img src="/assets/images/social/media_mail.gif" alt="Email" />
        </a>
      </div>
      <div className="py-4">
        <Link to="/">
          <img src="/assets/images/laesco_pie.gif" alt="LA ESCO" />
        </Link>
      </div>
    </div>
    <div className="text-center p-2">
      © 2005-2020 www.laesco.com.ar - Todos los derechos reservados
    </div>
  </div>
);

export default Footer;
