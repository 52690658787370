/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const SliderControl = ({ left, onClick }) => {
  const path = left ? 'M15 19l-7-7 7-7' : 'M9 5l7 7-7 7';
  return (
    <button
      type="button"
      onClick={onClick}
      className={`flex absolute top-0 ${
        left ? 'left-0' : 'right-0'
      } z-10 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none`}
    >
      <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
        <svg
          className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={path}
          />
        </svg>
        <span className="hidden">{left ? 'Previous' : 'Next'}</span>
      </span>
    </button>
  );
};

SliderControl.propTypes = {
  left: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const SliderIndicatorButton = ({ current, label, onClick }) => (
  <button
    type="button"
    className={`w-3 h-3 rounded-full ${
      current ? 'bg-white/50 border border-gray-600' : 'bg-gray-500/50'
    } `}
    aria-current={current ? 'true' : 'false'}
    aria-label={label}
    onClick={onClick}
  />
);

SliderIndicatorButton.propTypes = {
  current: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

SliderIndicatorButton.defaultProps = {
  current: false,
};

// eslint-disable-next-line object-curly-newline
const CarouselItem = ({ label, src, alt, current, link }) => (
  <div className={`${current ? '' : 'hidden'} duration-700 ease-in-out`}>
    {label && (
      <span className="absolute top-1/2 left-1/2 text-2xl font-semibold text-white -translate-x-1/2 -translate-y-1/2 sm:text-3xl dark:text-gray-800">
        {label}
      </span>
    )}
    {link ? (
      <Link to={link}>
        <img src={src} className="block" alt={alt} />
      </Link>
    ) : (
      <img src={src} className="block" alt={alt} />
    )}
  </div>
);

CarouselItem.propTypes = {
  label: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  current: PropTypes.bool,
  link: PropTypes.string,
};

CarouselItem.defaultProps = {
  label: null,
  alt: '',
  current: false,
  link: null,
};

const Carousel = ({ items }) => {
  const [current, setCurrent] = useState(0);
  const next = () => setCurrent((current + 1) % items.length);
  const prev = () => setCurrent(current === 0 ? items.length - 1 : current - 1);

  return (
    <div className="">
      <div id="default-carousel" className="relative" data-carousel="static">
        <div className="relative">
          {items &&
            items.map((item, index) => (
              <CarouselItem
                key={item.url}
                src={item.url}
                link={item?.link || null}
                alt={item.alt_text}
                current={current === index}
              />
            ))}
        </div>
        <div className="flex absolute bottom-5 left-1/2 z-10 space-x-3 -translate-x-1/2">
          {items &&
            items.map((item, index) => (
              <SliderIndicatorButton
                key={item.url}
                current={current === index}
                label={`Imagen ${index + 1}`}
                onClick={() => setCurrent(index)}
              />
            ))}
        </div>
        <SliderControl left onClick={prev} />
        <SliderControl left={false} onClick={next} />
      </div>
    </div>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Carousel;
