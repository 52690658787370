import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import Loading from './utils/LoadingComponent';
import Featured from './FeaturedComponent';
import WaveReport from './WaveReport';

const Card = ({ content }) => (
  <div className="w-full p-1 md:w-1/2 lg:w-1/3">
    <div className="bg-white h-full">
      <Link to={`/post/${content.id}`}>
        <img
          className=""
          // eslint-disable-next-line no-underscore-dangle
          src={content._embedded['wp:featuredmedia'][0].source_url}
          alt={content.title.rendered}
        />
        <div className="p-1">
          <h5 className="uppercase font-bold text-blue-700 hover:text-gray-800">
            {content.title.rendered}
          </h5>
          <div
            className="font-normal text-gray-700 ellipsis-container"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content.content.rendered }}
          />
        </div>
      </Link>
    </div>
  </div>
);

Card.propTypes = {
  content: PropTypes.shape().isRequired,
};

// eslint-disable-next-line object-curly-newline
const Index = ({ news, loading, success, waveReport, loadingReport }) => (
  <>
    <div className="home-featured-area">
      <Featured news={news} />
    </div>
    <div className="home-report-area">
      <WaveReport report={waveReport} loading={loadingReport} />
    </div>
    <div className="home-hadsleft-area">
      <img
        src="http://laesco.com.ar/wp-content/uploads/2017/01/lionroots.gif"
        className="img-fluid"
        alt="publicidad lion roots"
      />
    </div>
    <div className="home-hadsright-area">
      <img
        src="http://laesco.com.ar/wp-content/uploads/2020/01/banner_no_bad_days.gif"
        className="img-fluid"
        alt="publicidad no bad days"
      />
    </div>
    <div className="home-content-area flex flex-wrap">
      <Loading loading={loading} extraClasses="my-3">
        <>
          {!success && <div>News loading failed!</div>}
          {
            // eslint-disable-next-line operator-linebreak
            news &&
              news.map((content) => <Card key={content.id} content={content} />)
          }
        </>
      </Loading>
    </div>
  </>
);

Index.propTypes = {
  news: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  waveReport: PropTypes.shape(),
  loadingReport: PropTypes.bool.isRequired,
};

Index.defaultProps = {
  news: [],
  waveReport: null,
};

export default Index;
