const pad2 = (n) => String(n).padStart(2, '0');

export const stringToTime = (dateStr) => {
  if (!dateStr) {
    return '--:--';
  }
  const date = new Date(dateStr);
  return `${pad2(date.getHours())}:${pad2(date.getMinutes())}`;
};

export const stringToDate = (dateStr) => {
  if (!dateStr) {
    return '';
  }
  const date = new Date(dateStr);
  return `${date.getFullYear()}-${pad2(date.getMonth())}-${pad2(
    date.getDay(),
  )}`;
};
